import React from "react";
import back from "./assets/back.svg";
import { Link } from "gatsby";
import Authheader from "../layout/header/autheader";
export default function Create() {
  return (
    <>
      <Authheader />
      <div className="create  form">
        <div className="arrowback">
          <Link to="/signup">
            <img src={back} alt="back to signup" />
          </Link>
        </div>

        <h1>Create an account </h1>
        <p>Enter email and password</p>

        <div className="formgroup">
          <label>Email</label>
          <input placeholder="Emial" className="emial" type="emial" />
          <label>Password</label>
          <input placeholder="Password" className="password" type="password" />
          <label>confirm password</label>
          <input
            placeholder="Confirm Password"
            className="emial"
            type="password"
          />
        </div>
        <Link to="/signup/confirmation">
          <button className="next">I am a patient</button>
        </Link>
      </div>
    </>
  );
}
